<template>
  <auth-container>
    <h1 class="mb-3 text-center font-semibold font-roboto text-xlg text-black">
      {{ $t("password_forgot") }}
    </h1>
    <h3 class="text-grey-fontgrey w-96 text-center mb-7">
      {{ $t("password_forgot_text") }}
    </h3>
    <input-text
      data-testId="reset-email"
      class="mb-5 w-96"
      :name="'email'"
      :id="'email'"
      v-model="state.user.email"
      :label="$t('email')"
      :labelClass="'bg-white'"
      @keyup.enter="RESET_PASSWORD(state.user)"
    />
    <input-text
      data-testId="reset-email-confirmation"
      class="mb-5 w-96"
      :name="'email_confirmation'"
      :id="'email_confirmation'"
      v-model="state.user.email_confirmation"
      :label="$t('email_repeat')"
      :labelClass="'bg-white'"
      @keyup.enter="RESET_PASSWORD(state.user)"
    />
    <div class="flex flex-row w-96 gap-2">
      <div class="w-1/2">
        <custom-button
          @click="$router.push({ name: 'Login' })"
          class=" h-11"
          :text="$t('cancel')"
          :bgColor="'bg-grey-fontgrey'"
          :textColor="'text-white'"
        />
      </div>

      <custom-button
        @click="RESET_PASSWORD(state.user)"
        class=" h-11 w-1/2 "
        :text="$t('password_reset')"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :id="'reset-password-button'"
      />
    </div>
    <router-link
      tag="h4"
      :to="{ name: 'Login' }"
      class="absolute bottom-5 cursor-pointer text-grey-fontgrey transition-colors hover:text-grey-dark"
    >
      {{ $t("login_return") }}
    </router-link>
  </auth-container>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import CustomButton from "../../components/input/CustomButton.vue";
import InputText from "../../components/input/InputText.vue";
import { AUTH_TYPES, HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import AuthContainer from "./helpers/AuthContainer.vue";
export default defineComponent({
  name: "ResetPassword",
  components: { InputText, CustomButton, AuthContainer },
  setup() {
    const store = useStore();
    const state = reactive({
      user: {
        email: "",
        email_confirmation: "",
      },
    });
    /**
     * functions
     */
    function RESET_PASSWORD(user) {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "reset-password-button"
      );

      store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.RESET_PASSWORD}`, user);
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "reset-password-button"
      );
    }

    return {
      state,
      RESET_PASSWORD,
    };
  },
});
</script>

<style></style>
